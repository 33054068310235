import { useQuery } from '@apollo/client'
import { cacheCatalogueFilterParams } from '@cache'
import { Container, MobileGolfClubModelSearch } from '@components'
import { user } from '@graphql'
import { useDrawer, useWindowSize } from '@hooks'
import MobileFiltersArrow from '@images/link-arrow.svg'
import Options from '@images/option.svg'
import { CataloguePage } from '@pages/components'
import { useLocation } from '@reach/router'
import { CacheUser, ResponseType } from '@types'
import { navigate } from 'gatsby'
import React, { createRef, memo, useEffect } from 'react'
import './catalogue.scss'

interface CatalogueProps {}

const Catalogue: React.FC<CatalogueProps> = () => {
  const { openDrawer } = useDrawer('CatalogueFiltersDrawer')
  const catalogueHeroRef = createRef<HTMLDivElement>()
  const { toRender: isMobileScreen } = useWindowSize(['mobile', 'landscape'])

  const { data: currentUserData } = useQuery<ResponseType<CacheUser>>(user.FetchCurrentUser, {
    fetchPolicy: 'cache-only'
  })

  const currentUser = currentUserData?.res
  const { search } = useLocation()
  const urlSearchParams = new URLSearchParams(search)

  const userFlexParam = currentUser?.basicInfo.flex
  const userDexterityParam = currentUser?.basicInfo.dexterity
  useEffect(() => {
    if (currentUser) {
      userFlexParam ? urlSearchParams.set('flexes', `${userFlexParam}`) : urlSearchParams.set('flexes', 'REGULAR')
      userDexterityParam
        ? urlSearchParams.set('dexterities', `${userDexterityParam}`)
        : urlSearchParams.set('dexterities', 'RIGHT')
      currentUser ? urlSearchParams.set('mySettings', `true`) : urlSearchParams.set('mySettings', `false`)
      navigate(decodeURIComponent('?' + urlSearchParams.toString()), { replace: true })
    }
  }, [currentUser])

  useEffect(() => {
    cacheCatalogueFilterParams(search)
  }, [search])

  return (
    <section className={'catalogue'}>
      <Container>
        <div ref={catalogueHeroRef}>
          <CataloguePage.BannerCategories />
        </div>
        {isMobileScreen && (
          <div className="catalogue-mobile-search">
            <MobileGolfClubModelSearch />
          </div>
        )}
        {isMobileScreen && (
          <>
            <div className={'catalogue-mobile-heading-filters-button'} onClick={openDrawer}>
              <div>
                <Options />
                FILTER THE CATALOGUE
              </div>
              <MobileFiltersArrow />
            </div>
          </>
        )}

        <div className={'catalogue-grid'}>
          {!isMobileScreen && <CataloguePage.Filters />}
          <CataloguePage.Products />
        </div>
      </Container>
    </section>
  )
}

export default memo(Catalogue)
